





















































import { defineComponent, ref, watch } from "@vue/composition-api";
import {
  User,
  Applicant,
  Dog,
  ApplicantNote,
  Provider,
  Sponsor,
  DogTraining,
  ApplicantEmergencyContact,
  ApplicantFile,
} from "@/store";

interface Props {
  type: string;
  action: string;
  entity:
    | User
    | Applicant
    | Dog
    | ApplicantNote
    | ApplicantEmergencyContact
    | Provider
    | Sponsor
    | ApplicantFile
    | DogTraining;
  circle?: boolean;
  showDialog?: boolean;
  table?: boolean;
}

export default defineComponent({
  name: "ConfirmationDialog",
  props: {
    type: String,
    action: String,
    entity: Object,
    circle: Boolean,
    loading: Boolean,
    showDialog: Boolean,
    table: Boolean,
  },
  setup(props: Props, { emit }) {
    const dialog = ref<boolean>(false);
    const icon = ref<string>();
    const deleteText = ref<string>();

    watch(
      props,
      (newVal) => {
        if (newVal) {
          icon.value =
            props.circle && props.action === "Delete" ? "mdi-delete" : "";

          dialog.value = props.showDialog || dialog.value;

          if (props.type) {
            let entity:
              | Dog
              | Applicant
              | User
              | ApplicantNote
              | Provider
              | Sponsor
              | ApplicantEmergencyContact
              | ApplicantFile
              | DogTraining;
            switch (props.type) {
              case "Training Record":
                deleteText.value = "this training record?";
                break;
              case "Dog":
                entity = props.entity as Dog;
                deleteText.value = `${(entity || {}).name || ""}?`;
                break;
              case "User":
                entity = props.entity as User;
                deleteText.value = `${(entity || {}).firstName || ""} ${
                  (entity || {}).lastName || ""
                }?`;
                break;
              case "Note":
                entity = props.entity as ApplicantNote;
                deleteText.value = "this note?";
                break;
              case "Applicant":
                entity = props.entity as Applicant;
                deleteText.value = `${(entity || {}).firstName || ""} ${
                  (entity || {}).lastName || ""
                }?`;
                break;
              case "Provider":
                deleteText.value = "this provider from the applicant?";
                break;
              case "Sponsor":
                deleteText.value = "this sponsor?";
                break;
              case "Emergency Contact":
                deleteText.value = "this emergency contact?";
                break;
              case "Placement":
                entity = props.entity as Dog;
                deleteText.value = `this placement from ${
                  (entity || {}).name
                }?`;
                break;
              case "File":
                deleteText.value = `this file?`;
                break;
              case "Recipient Veterinarian":
                deleteText.value = `this veterinarian?`;
                break;
              case "Acquisition":
                entity = props.entity as Dog;
                deleteText.value = `this acquisition information from ${
                  (entity || {}).name
                }?`;
                break;
              case "Medical":
                entity = props.entity as Dog;
                deleteText.value = `this medical information from ${
                  (entity || {}).name
                }?`;
                break;
              default:
                return;
            }
          }
        }
      },
      {
        immediate: true,
      }
    );

    function submit() {
      dialog.value = false;

      if (props.type === "Note") {
        emit("deleteNote", props.entity.id);
      } else {
        emit(props.action.toLowerCase());
      }
    }

    function cancelDialog() {
      dialog.value = false;
      if (props.showDialog) {
        emit("cancel");
      }
    }

    return {
      dialog,
      submit,
      icon,
      cancelDialog,
      deleteText,
    };
  },
});
