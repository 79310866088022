




































import {
  computed,
  defineComponent,
  ref,
  watchEffect,
} from "@vue/composition-api";
import moment from "moment";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import {
  User,
  DogNote,
  DogNoteCreateBody,
  DogNoteUpdateBody,
  RuleFunction,
} from "@/store";

interface Props {
  loading: boolean;
  user: User;
  existingNote: DogNote;
  count: number;
  users?: User[];
  update?: boolean;
  isAdmin?: boolean;
  currentIndex?: number;
  validate?: boolean;
}

export default defineComponent({
  name: "DogNoteForm",
  props: {
    loading: Boolean,
    user: Object,
    existingNote: Object,
    count: Number,
    users: Array,
    update: Boolean,
    isAdmin: Boolean,
    currentIndex: Number,
    validate: Boolean,
  },
  components: {
    ConfirmationDialog,
  },
  setup(props: Props, { emit }) {
    const deletedNote = ref<boolean>(false);
    const readOnly = ref<boolean>(false);
    const showDialog = ref<boolean>(false);
    const index = ref<number>();
    const note = ref<string>(
      props.update && props.existingNote ? props.existingNote.text : ""
    );
    const author = ref<User>(
      (props.users || []).filter(
        (user) => (user || {}).id === (props.existingNote || {}).createdBy
      )[0]
    );
    const editor = ref<User>(
      (props.users || []).filter(
        (user) => (user || {}).id === (props.existingNote || {}).updatedBy
      )[0]
    );
    const valid = ref<boolean>(false);
    const noteForm = ref<{ validate: Function } | null>(null);
    const dogNote = ref<DogNoteCreateBody | DogNoteUpdateBody | null>(null);
    const noteRules = ref<RuleFunction<string>[]>([]);
    const userFullName = computed<string>(() => {
      if (props.user) {
        return `${(props.user || {}).firstName} ${(props.user || {}).lastName}`;
      }
      return "";
    });
    const noteAuthor = computed<string>(() => {
      if (props.users && props.existingNote.createdBy) {
        return (
          `${(author.value || {}).firstName} ${
            (author.value || {}).lastName
          }` || ""
        );
      } else return "";
    });
    const noteEditor = computed<string>(() => {
      if (props.users && props.existingNote.updatedBy) {
        return (
          `${(editor.value || {}).firstName} ${
            (editor.value || {}).lastName
          }` || ""
        );
      } else return "";
    });

    function getDate(date: Date) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    const noteLabel = computed<string>(() => {
      if (!props.existingNote.updatedBy) {
        return `Author: ${noteAuthor.value}---Created: ${getDate(
          (props.existingNote || {}).createDate
        )}`;
      } else {
        if (props.existingNote.createdBy === props.existingNote.updatedBy) {
          return `Author: ${noteAuthor.value}---Created: ${getDate(
            (props.existingNote || {}).createDate
          )}---Edited: ${getDate((props.existingNote || {}).updateDate)}`;
        } else
          return `Author: ${noteAuthor.value}---Created: ${getDate(
            (props.existingNote || {}).createDate
          )}---Edited by: ${noteEditor.value}---${getDate(
            (props.existingNote || {}).updateDate
          )}`;
      }
    });
    watchEffect(() => {
      if (props.validate && noteForm.value) {
        noteForm.value.validate();
      }
    });

    function setRequired() {
      noteRules.value = [
        (value: string) =>
          ((value || "").length && value.trim().length > 3) ||
          "Please include at least 4 characters",
      ];
    }

    function setNotRequired() {
      valid.value = true;
      noteRules.value = [];
    }

    watchEffect(() => {
      if (props.users && props.update) {
        author.value = props.users.filter(
          (user) => (user || {}).id === (props.existingNote || {}).createdBy
        )[0];
      }
    });

    function getReadOnly() {
      if (props.isAdmin || props.user.id === props.existingNote.createdBy) {
        readOnly.value = false;
      } else {
        readOnly.value = true;
      }
    }

    function setDogNote(event: string) {
      index.value = 0;
      if (event !== null) {
        setRequired();
      } else {
        valid.value = true;
        setNotRequired();
      }
      if ((event || "").length >= 4) {
        valid.value = true;
      } else if (
        (!props.existingNote && (note.value || "").length >= 4) ||
        event === null
      ) {
        valid.value = true;
      } else {
        valid.value = false;
      }
      emit("dogNote", { valid: valid.value });

      if (props.count > 0) {
        props.count === 1 ? (index.value = 1) : (index.value = props.count);
      }

      if (!props.existingNote && !props.update) {
        if (event === null || event.length === 0) {
          valid.value = true;
          setNotRequired();
        }
        dogNote.value = {
          text: event,
          createdBy: (props.user || {}).id,
          createDate: new Date(),
        };

        emit("dogNote", {
          note: dogNote.value,
          index: index.value,
          valid: valid.value,
          type: "new",
        });
      } else if (
        valid.value &&
        props.existingNote &&
        props.update &&
        (event || "").trim().length > 3
      ) {
        dogNote.value = {
          id: props.existingNote.id,
          text: event,
          createdBy: props.existingNote.createdBy,
          createDate: props.existingNote.createDate,
          updatedBy: (props.user || {}).id,
          updateDate: new Date(),
        };
        emit("dogNote", {
          note: dogNote.value,
          index: props.currentIndex,
          valid: valid.value,
        });
      } else if (event === null || event.length === 0) {
        showDialog.value = true;
      }
    }

    function deleteNote(event: number) {
      deletedNote.value = true;
      showDialog.value = false;
      valid.value = true;
      emit("deleteDogNote", event);
    }

    function cancelDialog() {
      showDialog.value = false;
      note.value = props.existingNote.text;
    }

    function clearNewNote() {
      if (!props.existingNote) {
        valid.value = true;
        emit("dogNote", {
          valid: true,
          index: props.currentIndex,
          type: "new",
        });
      }
    }

    return {
      note,
      valid,
      userFullName,
      setDogNote,
      noteRules,
      noteLabel,
      readOnly,
      getReadOnly,
      showDialog,
      cancelDialog,
      deleteNote,
      clearNewNote,
      deletedNote,
    };
  },
});
