var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"dogTrainingForm",attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{class:{ 'ma-0': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-row',[_c('v-col',{staticClass:"mr-4"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? "Training Entry" : "Service Dog Training Entry")+" ")])])],1),_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pb-0' : '',attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-tag","disabled":!(_vm.dogs && _vm.dogs.length),"items":_vm.dogs,"label":"Select A Dog","hint":"dog","persistent-hint":"","item-text":"name","item-value":"id","return-object":"","single-line":"","rules":_vm.dogRules},model:{value:(_vm.selectedDog),callback:function ($$v) {_vm.selectedDog=$$v},expression:"selectedDog"}})],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pb-0' : '',attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"prepend-icon":"mdi-calendar","value":_vm._f("formatDate")(_vm.date),"label":"Training Date","date":"","readonly":"","disabled":!_vm.selectedDog,"persistent-hint":"","hint":!_vm.selectedDog ? 'disabled until dog selected' : 'date'}},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.date,"max":_vm.today,"min":_vm.minDate},on:{"change":function($event){return _vm.changeDate($event)}}})],1)],1)],1),_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pb-0' : '',attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-av-timer","type":"number","step":"10","min":"0","max":"1440","hint":!_vm.regularTraining || _vm.regularTraining <= 0
              ? 'record in minutes'
              : (_vm.regularTraining / 60).toPrecision(2) + ' hours',"persistent-hint":"","label":"Total Regular Training","suffix":_vm.$vuetify.breakpoint.mdAndUp ? 'minutes' : '',"rules":_vm.regularTrainingRules},model:{value:(_vm.regularTraining),callback:function ($$v) {_vm.regularTraining=$$v},expression:"regularTraining"}})],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pb-0' : '',attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-av-timer","hint":!_vm.publicAccessTraining || _vm.publicAccessTraining <= 0
              ? 'record in minutes'
              : (_vm.publicAccessTraining / 60).toPrecision(2) + ' hours',"persistent-hint":"","type":"number","step":"10","min":"0","max":"1440","label":"Total Public Access","suffix":_vm.$vuetify.breakpoint.mdAndUp ? 'minutes' : '',"rules":_vm.publicAccessTrainingRules},model:{value:(_vm.publicAccessTraining),callback:function ($$v) {_vm.publicAccessTraining=$$v},expression:"publicAccessTraining"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-dog-service","items":_vm.dogTrainers,"readonly":!_vm.isStaffOrHigher,"label":"Select A Dog Trainer","hint":"dog trainer","persistent-hint":"","item-text":"email","item-value":"id","return-object":"","single-line":"","rules":_vm.dogTrainerRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}}]),model:{value:(_vm.dogTrainer),callback:function ($$v) {_vm.dogTrainer=$$v},expression:"dogTrainer"}},[(!_vm.hideHistory)?_c('template',{slot:"append-outer"},[_c('v-btn',{attrs:{"small":"","color":_vm.dogTrainer && _vm.dogTrainer.isDogTrainer ? 'primary' : '',"text":_vm.$vuetify.breakpoint.mdAndUp,"disabled":!_vm.dogTrainer,"icon":!_vm.$vuetify.breakpoint.mdAndUp,"to":_vm.currentUser &&
                _vm.currentUser.role &&
                _vm.currentUser.role.id === _vm.USER_ROLES.DOG_TRAINER
                  ? {
                      name: 'Dashboard',
                    }
                  : {
                      name: 'TrainerDogList',
                      params: { id: _vm.dogTrainer ? _vm.dogTrainer.id : '' },
                    },"link":"","title":"Dog Trainer History"}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-icon',[_vm._v("mdi-history")]):_vm._e(),_vm._v(_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "History" : "")+" ")],1)],1):_vm._e()],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_vm._t("additional-buttons"),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","type":"submit","disabled":_vm.loading}},[_vm._v(" Submit ")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }