var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"items":_vm.trainings,"headers":_vm.headers,"loading":_vm.loading,"page":_vm.page,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-flex',{staticClass:"d-flex justify-space-between pt-3"},[_c('v-flex',{staticClass:"pl-2"},[_c('v-card-subtitle',[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',[_c('strong',{staticClass:"pr-2"},[_vm._v("Total Public Training Hours:")]),_vm._v(" "+_vm._s(_vm.getTrainingHours(_vm.getTotalPublicHours()) || "0:00")+" ")])],1),_c('v-row',{attrs:{"align":"start"}},[_c('v-col',[_c('strong',{staticClass:"pr-2"},[_vm._v("Total Regular Training Hours: ")]),_vm._v(" "+_vm._s(_vm.getTrainingHours(_vm.getTotalRegularHours()) || "0:00")+" ")])],1),_c('v-row',{attrs:{"align":"start"}},[_c('v-col',[_c('strong',{staticClass:"pr-2"},[_vm._v("Total Training Hours:")]),_vm._v(" "+_vm._s(_vm.getTrainingHours( _vm.getTotalPublicHours() + _vm.getTotalRegularHours() ) || "0:00")+" ")])],1)],1)],1),(_vm.update)?_c('v-flex',{staticClass:"d-flex justify-end mr-2"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","dark":""}},on),[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v("mdi-plus-circle")]):_c('span',[_vm._v("Create Training Entry")])],1)]}}],null,false,1730848456),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('dog-training-form',{attrs:{"loading":_vm.loading,"dogTrainers":_vm.dogTrainers,"dogs":[_vm.dog],"currentUser":_vm.user,"isStaffOrHigher":_vm.isStaffOrHigher,"hideHistory":_vm.update},on:{"create":_vm.createTraining},scopedSlots:_vm._u([{key:"additional-buttons",fn:function(){return [_c('span',{staticClass:"ml-2"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":36,"color":"primary","indeterminate":""}}):_vm._e()],1),_c('span',{staticClass:"ml-2"},[(_vm.update)?_c('v-btn',{attrs:{"text":"","size":36,"color":"primary","indeterminate":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]):_vm._e()],1)]},proxy:true}],null,false,1661613993)})],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user ? _vm.getName(item.user) : "")+" ")]}},{key:"item.publicAccessTraining",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTrainingHours(item.publicAccessTraining) || "0:00")+" ")]}},{key:"item.regularTraining",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getTrainingHours(item.regularTraining) || "0:00")+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.date ? _vm.getDate(item.date) : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('confirmation-dialog',{attrs:{"table":"","entity":item,"action":"Delete","type":"Training Record"},on:{"delete":function($event){return _vm.deleteTraining(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }