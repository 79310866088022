





















































































































































































import { defineComponent, ref, watch, watchEffect } from "@vue/composition-api";
import { User, Dog, RuleFunction, DogTrainingCreateBody } from "@/store";
import { USER_ROLES } from "@/store/constants";
import { Vue } from "vue/types/vue";
interface Props {
  loading: boolean;
  dogTrainers: Array<User>;
  dogs: Array<Dog>;
  currentUser: User;
  isStaffOrHigher: boolean;
  hideHistory?: boolean;
}

export type VForm = Vue & {
  validate: () => boolean;
  reset: () => boolean;
  resetValidation: () => boolean;
};

export default defineComponent({
  name: "DogTrainingForm",
  props: {
    loading: Boolean,
    dogTrainers: Array,
    currentUser: Object,
    dogs: Array,
    isStaffOrHigher: Boolean,
    hideHistory: Boolean,
  },
  setup(props: Props, { emit }) {
    const name = ref<string>("");
    const today = new Date().toISOString();
    const minDate = ref<Date | string>(today);
    const date = ref<Date | string>(today.substr(0, 10));
    const regularTraining = ref<number | undefined>();
    const publicAccessTraining = ref<number | undefined>();
    const document = ref<File | null>(null);
    const valid = ref<boolean>(false);
    const selectedDog = ref<Dog | null>(
      props.dogs && props.dogs.length === 1 ? props.dogs[0] : null
    );
    const dogTrainer = ref<User | null>(
      props.currentUser && props.currentUser.isDogTrainer
        ? props.currentUser
        : null
    );

    const dogTrainingForm = ref<{ validate: Function } | null>(null);
    const dateMenu = ref<boolean>(false);

    const regularTrainingRules = ref<RuleFunction<number>[]>([
      (value: number) =>
        !!value ||
        !!publicAccessTraining.value ||
        "Regular or Public Access entry is required",
      (value: number) =>
        value > 0 ||
        (publicAccessTraining.value && publicAccessTraining.value > 0) ||
        "Public Access or Regular entry has to be greater than 0",
      (value: number) =>
        value >= 0 || "Value must be greater than or equal to 0",
      (value: number) => value < 1440 || "Value must be less than 1440",
    ]);

    const publicAccessTrainingRules = ref<RuleFunction<number>[]>([
      (value: number) =>
        !!value ||
        !!regularTraining.value ||
        "Public Access or Regular entry is required",
      (value: number) =>
        value > 0 ||
        (regularTraining.value && regularTraining.value > 0) ||
        "Public Access or Regular entry has to be greater than 0",
      (value: number) =>
        value >= 0 || "Value must be greater than or equal to 0",
      (value: number) => value <= 1440 || "Value must be less than 1440",
    ]);

    const dogRules = ref<RuleFunction<Dog>[]>([
      (value) => !!value || "Dog is Required.",
    ]);

    const dogTrainerRules = ref<RuleFunction<User>[]>([
      (value) => !!value || "Dog Trainer is Required.",
    ]);

    watch(regularTraining, () => {
      if (!publicAccessTraining.value) {
        publicAccessTraining.value = 0;
      }
      if (dogTrainingForm.value) {
        dogTrainingForm.value.validate();
      }
    });

    watch(publicAccessTraining, () => {
      if (!regularTraining.value) {
        regularTraining.value = 0;
      }
      if (dogTrainingForm.value) {
        dogTrainingForm.value.validate();
      }
    });

    watchEffect(() => {
      if (selectedDog && selectedDog.value && selectedDog.value.birthDate) {
        minDate.value = selectedDog.value.birthDate;
      }
    });

    function resetForm() {
      regularTraining.value = undefined;
      publicAccessTraining.value = undefined;
      (dogTrainingForm.value as VForm).resetValidation();
    }

    function submit() {
      if (
        valid.value &&
        selectedDog.value &&
        dogTrainer.value &&
        (regularTraining.value || publicAccessTraining.value)
      ) {
        const body: DogTrainingCreateBody = {
          date: date.value,
          regularTraining: regularTraining.value || 0,
          publicAccessTraining: publicAccessTraining.value || 0,
          user: dogTrainer.value.id,
        };
        emit("create", selectedDog.value.id, body);
        resetForm();
      } else {
        if (dogTrainingForm.value) {
          dogTrainingForm.value.validate();
        }
      }
    }

    function changeDate(event: Date) {
      date.value = new Date(event).toISOString().substr(0, 10);
    }

    return {
      name,
      date,
      regularTraining,
      publicAccessTraining,
      dogTrainer,
      document,
      selectedDog,
      submit,
      valid,
      dogTrainingForm,
      dateMenu,
      changeDate,
      today,
      minDate,
      regularTrainingRules,
      publicAccessTrainingRules,
      dogRules,
      dogTrainerRules,
      USER_ROLES,
    };
  },
});
