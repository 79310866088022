




















































































































import { defineComponent, ref, computed } from "@vue/composition-api";
import moment from "moment";
import { User, DogTraining, Dog, DogTrainingCreateBody } from "@/store";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import DogTrainingForm from "@/components/forms/DogTraining.vue";

interface Props {
  trainings: DogTraining[];
  loading: boolean;
  total: number;
  update?: boolean;
  dogTrainer?: boolean;
  dogTrainers?: User[];
  dog?: Dog;
  user?: User;
  isStaffOrHigher?: boolean;
  trainerIsUser?: boolean;
}

export interface Pagination {
  page: number;
  itemsPerPage: number;
  pageStart: number;
  pageStop: number;
  pageCount: number;
  itemsLenth: number;
}

interface Header {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "DogTrainingsTable",
  props: {
    trainings: Array,
    loading: Boolean,
    total: Number,
    update: Boolean,
    dogTrainer: Boolean,
    dogTrainers: Array,
    dog: Object,
    user: Object,
    isStaffOrHigher: Boolean,
    trainerIsUser: Boolean,
  },
  components: {
    ConfirmationDialog,
    DogTrainingForm,
  },
  setup(props: Props, { emit }) {
    const headers = computed<Header[]>(() => {
      const heads = [
        { text: "Trainer", value: "user", sortable: true },
        { text: "Regular Hours", value: "regularTraining", sortable: true },
        { text: "Public Hours", value: "publicAccessTraining", sortable: true },
        { text: "Date", value: "date", sortable: true },
      ];
      if (props.update || (!props.update && props.trainerIsUser)) {
        heads.push({ text: "Actions", value: "actions", sortable: false });
      }
      if (props.dogTrainer) {
        heads[0] = { text: "Dog", value: "name", sortable: true };
      }
      return heads;
    });
    const sortBy = ref<string>("date");
    const sortDesc = ref<boolean>(true);
    const dialog = ref<boolean>(false);
    const page = ref<number>(1);
    const itemsPerPage = ref<number>(10);
    const pageCount = computed<number>(() =>
      Math.ceil(props.total / itemsPerPage.value)
    );
    const search = ref<string>("");

    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    function getName(user: User) {
      return `${user.firstName} ${user.lastName}`;
    }

    function getTrainingHours(trainingTime: string) {
      const time = parseInt(trainingTime);
      if (time <= 0 || isNaN(time)) {
        return;
      }
      const hours = Math.floor(time / 60).toString();
      let minutes = (time % 60 || 0).toString();
      minutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes.toString();
      return hours + ":" + minutes;
    }

    function getTotalPublicHours() {
      let publicHours;
      const trainings = (props.trainings || []).map((training) =>
        parseInt(training.publicAccessTraining as string)
      );
      if (trainings.length) {
        publicHours = trainings.reduce(
          (acc: number, time: number) => acc + time
        );
      }

      return publicHours;
    }

    function getTotalRegularHours() {
      let regularHours;
      const trainings = (props.trainings || []).map((training) =>
        parseInt(training.regularTraining as string)
      );
      if (trainings.length) {
        regularHours = trainings.reduce(
          (acc: number, time: number) => acc + time
        );
      }

      return regularHours;
    }

    function deleteTraining(item: DogTraining) {
      emit("deleteTraining", item);
    }

    function createTraining(
      dogId: number | string,
      body: DogTrainingCreateBody
    ) {
      if (body && typeof body.user === "string") {
        body.user = props.dogTrainers?.find((x) => x.id == body.user) as User;
        if (body.user.role) {
          delete body.user.role;
        }
      }
      emit("createTraining", { ...body });
      dialog.value = false;
    }

    return {
      sortBy,
      sortDesc,
      dialog,
      headers,
      page,
      pageCount,
      itemsPerPage,
      search,
      getDate,
      getName,
      getTrainingHours,
      getTotalPublicHours,
      getTotalRegularHours,
      deleteTraining,
      createTraining,
    };
  },
});
