





















































































































import { defineComponent, ref, watch, watchEffect } from "@vue/composition-api";
import { ACQUISITION_TYPE } from "@/store/constants";
import { Dog, DogFile, RuleFunction } from "@/store";
import { useFileValidation } from "@/composables/validation/file";
import moment from "moment";
import FileDialog from "@/components/dialogs/File.vue";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";

interface Props {
  loading: boolean;
  validate?: boolean;
  update?: boolean;
  dog?: Dog;
}

export default defineComponent({
  name: "DogAcquisitionForm",
  props: {
    loading: Boolean,
    validate: Boolean,
    update: Boolean,
    dog: Object,
  },
  components: {
    FileDialog,
    ConfirmationDialog,
  },
  setup(props: Props, { emit }) {
    const valid = ref<boolean>(true);
    const dogAcquisitionForm = ref<{ validate: Function } | null>(null);
    const acquisitionType = ref<number | null>();
    const acquiredDateMenu = ref<boolean>(false);
    const acquiredDate = ref<Date | string | undefined>("");
    const acquisitionFiles = ref<File[] | null>(null);
    const existingAcquisitionFiles = ref<DogFile[] | File | undefined>(
      ((props.dog || {}).attachments || []).filter(
        (attachment) => attachment.type === "acquisitionFile"
      )
    );
    const { fileArrayRules } = useFileValidation();
    const typeRules = ref<RuleFunction<number>[]>([]);
    const dateRules = ref<RuleFunction<string>[]>([]);
    const deletedFiles = ref<number[]>([]);
    const showAcquisitionFileInput = ref<boolean>(false);

    watchEffect(() => {
      if (props.validate && dogAcquisitionForm.value) {
        dogAcquisitionForm.value.validate();
      }
    });

    function setRequired() {
      dateRules.value = [
        (value: string | Date) => !!value || "Date is required",
      ];
      typeRules.value = [(value: number) => !!value || "Type is required"];
    }

    function setNotRequired() {
      dateRules.value = [];
      typeRules.value = [];
    }

    async function setAcquisitionStatus() {
      if (acquisitionType.value) {
        await setRequired();
      } else {
        await setNotRequired();
      }

      emit("setAcquisition", { valid: valid.value });

      watchEffect(() => {
        if (dogAcquisitionForm.value && valid.value) {
          setTimeout(() => {
            emit("setAcquisition", {
              acquisitionData: {
                acquisitionType: acquisitionType.value,
                acquiredDate: acquiredDate.value,
                acquisitionFiles: acquisitionFiles.value,
              },
              valid: valid.value,
            });
          }, 300);
        }
      });
    }

    watch(acquisitionFiles, () => {
      setTimeout(() => {
        emit("setAcquisition", {
          valid: valid.value,
          acquisitionData: {
            acquisitionType: acquisitionType.value,
            acquiredDate: acquiredDate.value,
            acquisitionFiles: acquisitionFiles.value,
          },
        });
      }, 300);
    });

    function changeDate($event: Date) {
      acquiredDate.value = new Date($event).toISOString().substr(0, 10);
    }

    function deleteChip(index: number) {
      (acquisitionFiles.value || []).splice(index, 1);
    }

    function getDate(date: Date | string) {
      date = new Date(date).toISOString().substr(0, 10);
      return moment(String(date)).format("MM/DD/YYYY");
    }

    async function clearAll() {
      await setNotRequired();
      acquisitionType.value = null;
      acquiredDate.value = "";
      acquisitionFiles.value = null;
    }

    async function setFields(dog: Dog) {
      acquisitionType.value = dog.acquisitionType;
      if (dog.acquiredDate) {
        acquiredDate.value = new Date(dog.acquiredDate)
          .toISOString()
          .substr(0, 10);
      }
      await setRequired();
    }

    function deleteAcquisitionFile(file: DogFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingAcquisitionFiles.value = (
        (existingAcquisitionFiles.value as DogFile[]) || []
      ).filter((attachment: DogFile) => attachment.id !== file.id);
    }

    async function removeAcquisition() {
      await clearAll();
      for (const file of (existingAcquisitionFiles.value as DogFile[]) || []) {
        await deleteAcquisitionFile(file);
      }
      showAcquisitionFileInput.value = false;
      emit("setAcquisition", {
        valid: valid.value,
        type: "delete",
      });
    }

    function showFileInput() {
      if (!props.update && !acquisitionType.value) {
        return false;
      }

      if (!(props.update && !showAcquisitionFileInput.value)) {
        return true;
      }

      if (
        props.update &&
        acquisitionType.value &&
        !((existingAcquisitionFiles.value as DogFile[]) || []).length
      ) {
        return true;
      }

      return false;
    }

    function showDelete() {
      return !!(props.update && (props.dog || {}).acquisitionType);
    }

    if (props.update) {
      if (props.dog) {
        setFields(props.dog as Dog);
      } else {
        setNotRequired();
      }
    }

    return {
      dogAcquisitionForm,
      valid,
      ACQUISITION_TYPE,
      acquisitionType,
      acquiredDateMenu,
      acquiredDate,
      changeDate,
      acquisitionFiles,
      deleteChip,
      fileArrayRules,
      typeRules,
      dateRules,
      setAcquisitionStatus,
      clearAll,
      existingAcquisitionFiles,
      deleteAcquisitionFile,
      showAcquisitionFileInput,
      showFileInput,
      getDate,
      showDelete,
      removeAcquisition,
    };
  },
});
