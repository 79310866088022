











































































































































import {
  defineComponent,
  ref,
  watchEffect,
  computed,
} from "@vue/composition-api";
import { RuleFunction, Applicant, Dog, DogFile } from "@/store";
import moment from "moment";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import { useFileValidation } from "@/composables/validation";
import FileDialog from "@/components/dialogs/File.vue";

interface Props {
  loading: boolean;
  validate?: boolean;
  applicants?: Applicant[];
  update?: boolean;
  dog?: Dog;
}

interface Recipient {
  value: number;
  text: string;
}

enum STATUSES {
  Placed = 1,
  Adopted,
  Retired,
  Deceased,
}

export default defineComponent({
  name: "DogPlacementForm",
  props: {
    loading: Boolean,
    validate: Boolean,
    applicants: Array,
    update: Boolean,
    dog: Object,
  },
  components: {
    ConfirmationDialog,
    FileDialog,
  },
  setup(props: Props, { emit }) {
    const valid = ref<boolean>(false);
    const dogPlacementForm = ref<{ validate: Function } | null>(null);
    const adoptionFile = ref<File | null>(null);
    const existingAdoptionFile = ref<DogFile[] | File | undefined>(
      ((props.dog || {}).attachments || []).filter(
        (attachment) => attachment.type === "adoptionFile"
      )
    );
    const deletedFiles = ref<number[]>([]);
    const placementStatus = ref<number | null>(null);
    const adoptionDate = ref<Date | string | undefined>("");
    const recipient = ref<number | null>(null);
    const showAdoption = ref<boolean>(false);
    const adoptionDateMenu = ref<boolean>(false);
    const adoptionDateRules = ref<RuleFunction<Date>[]>([]);
    const placementDateRules = ref<RuleFunction<Date>[]>([]);
    const recipientRules = ref<RuleFunction<number>[]>([]);
    const placementDate = ref<Date | string | undefined>("");
    const placementDateMenu = ref<boolean>(false);
    const placementClearable = computed<boolean>(
      () =>
        !(
          props?.dog?.adoptionDate?.toString().length ||
          props?.dog?.placementDate?.toString().length ||
          props?.dog?.retired ||
          props?.dog?.deceased
        )
    );
    const PLACEMENT_STATUSES = [
      { value: STATUSES.Placed, text: "Placed" },
      { value: STATUSES.Adopted, text: "Adopted" },
      { value: STATUSES.Retired, text: "Retired" },
      { value: STATUSES.Deceased, text: "Deceased" },
    ];
    const { fileRules } = useFileValidation();

    function getDate(date: Date | string) {
      date = new Date(date).toISOString().substr(0, 10);
      return moment(String(date)).format("MM/DD/YYYY");
    }

    function getTransformedApplicants() {
      return (props.applicants || [])
        .filter(
          (recipient) =>
            (!recipient.dog ||
              recipient.id === ((props.dog || {}).applicant || {}).id) &&
            !recipient.deleted
        )
        .sort((a: Applicant, b: Applicant) => {
          if (a.lastName > b.lastName) return 1;
          else if (b.lastName > a.lastName) return -1;
          else return 0;
        })
        .map((applicant) => {
          return {
            value: applicant.id,
            text: `Name: ${applicant.lastName}, ${
              applicant.firstName
            }---DOB: ${getDate(applicant.birthDate)}`,
          };
        });
    }

    watchEffect(() => {
      if (props.validate && dogPlacementForm.value) {
        dogPlacementForm.value.validate();
      }
    });

    const recipients = computed<Recipient[]>(() => getTransformedApplicants());

    function changeDate(event: Date, type: string) {
      if (type == "adoption") {
        adoptionDate.value = new Date(event).toISOString().substr(0, 10);
      }

      if (type === "placement") {
        placementDate.value = new Date(event).toISOString().substr(0, 10);
      }
    }

    function setPlacementRequired() {
      placementDateRules.value = [
        (value: Date) => !!value || "Placement date is required",
      ];

      recipientRules.value = [
        (value: number) => !!value || "Recipient is required",
      ];
    }

    function setPlacementNotRequired() {
      placementDateRules.value = [];
      recipientRules.value = [];
    }

    function setAdoptionRequired() {
      adoptionDateRules.value = [
        (value: Date) => !!value || "Adoption date required",
      ];
    }

    function setAdoptionNotRequired() {
      adoptionDateRules.value = [];
    }

    function deleteAdoptionFile(file: DogFile) {
      deletedFiles.value.push(file.id);
      emit("deleteAdoptionFile", deletedFiles.value);
      existingAdoptionFile.value = [];
    }

    async function clearAll(removeValidation = true) {
      if (removeValidation) {
        await setPlacementNotRequired();
        await setAdoptionNotRequired();
      }
      placementDate.value = "";
      adoptionDate.value = "";
      adoptionFile.value = null;
      if ((existingAdoptionFile.value as DogFile[])[0]) {
        deleteAdoptionFile((existingAdoptionFile.value as DogFile[])[0]);
      }
      recipient.value = null;

      emit("setPlacement", { valid: valid.value });
    }

    async function removePlacement() {
      await clearAll();
      placementStatus.value = null;
      emit("setPlacement", {
        valid: valid.value,
        type: "delete",
      });
    }

    async function setPlacementStatus() {
      switch (placementStatus.value) {
        case STATUSES.Placed: {
          await setPlacementRequired();
          await setAdoptionNotRequired();
          break;
        }
        case STATUSES.Adopted: {
          await setAdoptionRequired();
          await setPlacementNotRequired();
          break;
        }
        default: {
          await setAdoptionNotRequired();
          await setPlacementNotRequired();
          break;
        }
      }
    }

    watchEffect(() => {
      switch (placementStatus.value) {
        case STATUSES.Placed: {
          emit("setPlacement", {
            valid: valid.value,
            placementData: {
              placementDate: placementDate.value,
              recipient: recipient.value,
            },
            type: "placement",
          });
          break;
        }
        case STATUSES.Adopted: {
          emit("setPlacement", {
            valid: valid.value,
            placementData: {
              adoptionDate: adoptionDate.value,
              adoptionFile: adoptionFile.value,
            },
            type: "adoption",
          });
          break;
        }
        case STATUSES.Retired: {
          emit("setPlacement", {
            valid: valid.value,
            type: "retired",
          });
          break;
        }
        case STATUSES.Deceased: {
          emit("setPlacement", {
            valid: valid.value,
            type: "deceased",
          });
          break;
        }
        default: {
          emit("setPlacement", { valid: valid.value });
          break;
        }
      }
    });

    function showDelete() {
      return (
        props.update &&
        (props.dog?.placementDate ||
          props.dog?.adoptionDate ||
          props.dog?.retired ||
          props.dog?.deceased)
      );
    }

    async function setFields(dog: Dog) {
      if (dog.placementDate) {
        placementStatus.value = 1;
        placementDate.value = new Date(dog.placementDate)
          .toISOString()
          .substr(0, 10);
        recipient.value = (dog.applicant || {}).id;
        await setPlacementRequired();
        await setAdoptionNotRequired();
      } else if (dog.adoptionDate) {
        placementStatus.value = 2;
        adoptionDate.value = new Date(dog.adoptionDate)
          .toISOString()
          .substr(0, 10);
        recipient.value = null;
        await setAdoptionRequired();
        await setPlacementNotRequired();
      } else if (dog.retired) {
        placementStatus.value = 3;
        setAdoptionNotRequired();
        setPlacementNotRequired();
      } else if (dog.deceased) {
        placementStatus.value = 4;
        setAdoptionNotRequired();
        setPlacementNotRequired();
      }
    }

    if (props.update) {
      if (props.dog) {
        setFields(props.dog as Dog);
      } else {
        setAdoptionNotRequired();
        setPlacementNotRequired();
      }
    }

    return {
      dogPlacementForm,
      valid,
      placementStatus,
      STATUSES,
      PLACEMENT_STATUSES,
      placementClearable,
      changeDate,
      adoptionDateMenu,
      adoptionDate,
      showAdoption,
      placementDate,
      placementDateMenu,
      recipients,
      adoptionDateRules,
      placementDateRules,
      recipientRules,
      recipient,
      setPlacementStatus,
      showDelete,
      clearAll,
      removePlacement,
      adoptionFile,
      existingAdoptionFile,
      deleteAdoptionFile,
      fileRules,
      getDate,
    };
  },
});
