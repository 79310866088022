
























































































































































































































































































































import {
  defineComponent,
  ref,
  watch,
  watchEffect,
  Ref,
} from "@vue/composition-api";
import { useFileValidation } from "@/composables/validation/file";
import { Dog, DogFile, DogMedical } from "@/store";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import FileDialog from "@/components/dialogs/File.vue";
import moment from "moment";

interface Props {
  loading: boolean;
  validate?: boolean;
  update?: boolean;
  dog?: Dog;
}

export default defineComponent({
  name: "DogMedicalForm",
  props: {
    loading: Boolean,
    validate: Boolean,
    update: Boolean,
    dog: Object,
  },
  components: {
    FileDialog,
    ConfirmationDialog,
  },
  setup(props: Props, { emit }) {
    const valid = ref<boolean>(true);
    const dogMedicalForm = ref<{ validate: Function } | null>(null);
    const medicalNotesFiles = ref<File[] | null>(null);
    const existingMedicalNotesFiles = ref<DogFile[] | File | undefined>(
      ((props.dog || {}).attachments || []).filter(
        (attachment) => attachment.type === "medicalNotesFile"
      )
    );
    const showMedicalRecordsInput = ref<boolean>(false);
    const showMedicalNotesInput = ref<boolean>(false);
    const medicalRecordsFiles = ref<File[] | null>(null);
    const existingMedicalRecordsFiles = ref<DogFile[] | File | undefined>(
      ((props.dog || {}).attachments || []).filter(
        (attachment) => attachment.type === "medicalRecordsFile"
      )
    );
    const parvovirusDateMenu = ref<boolean>(false);
    const parvovirusDue = ref<Date | string>("");
    const dhpDateMenu = ref<boolean>(false);
    const dhpDue = ref<Date | string>("");
    const leptospirosisDateMenu = ref<boolean>(false);
    const leptospirosisDue = ref<Date | string>("");
    const bordetellaDateMenu = ref<boolean>(false);
    const bordetellaDue = ref<Date | string>("");
    const rabiesDateMenu = ref<boolean>(false);
    const rabiesDue = ref<Date | string>("");
    const spayedNeuteredDateMenu = ref<boolean>(false);
    const spayedNeutered = ref<Date | string>("");
    const deletedFiles = ref<number[]>([]);
    const { fileArrayRules } = useFileValidation();
    const removeClicked = ref<boolean>(false);

    watchEffect(() => {
      if (props.validate && dogMedicalForm.value) {
        dogMedicalForm.value.validate();
      }
    });

    function setMedicalAndEmit() {
      emit("setMedical", {
        medicalData: {
          dhpDue: dhpDue.value,
          bordetellaDue: bordetellaDue.value,
          leptospirosisDue: leptospirosisDue.value,
          parvovirusDue: parvovirusDue.value,
          rabiesDue: rabiesDue.value,
          spayedNeutered: spayedNeutered.value,
        },
        files: {
          medicalNotesFiles: medicalNotesFiles.value,
          medicalRecordsFiles: medicalRecordsFiles.value,
        },
        valid: valid.value,
      });
    }

    function setMedicalInfo() {
      watchEffect(() => {
        setTimeout(() => {
          setMedicalAndEmit();
        }, 300);
      });
    }

    watch(medicalNotesFiles, () => {
      setTimeout(() => {
        setMedicalAndEmit();
      }, 300);
    });

    watch(medicalRecordsFiles, () => {
      setTimeout(() => {
        setMedicalAndEmit();
      }, 300);
    });

    function setDateField(filed: Ref<string | Date>, val?: string | Date) {
      if (val) {
        filed.value = new Date(val)?.toISOString()?.substr(0, 10);
      }
    }

    function changeDate(event: Date, type: string) {
      switch (type) {
        case "parvovirus":
          setDateField(parvovirusDue, event);
          break;
        case "dhp":
          setDateField(dhpDue, event);
          break;
        case "leptospirosis":
          setDateField(leptospirosisDue, event);
          break;
        case "bordetella":
          setDateField(bordetellaDue, event);
          break;
        case "rabies":
          setDateField(rabiesDue, event);
          break;
        case "spayedneutered":
          setDateField(spayedNeutered, event);
          break;
        default:
          null;
      }
      setMedicalInfo();
    }

    function clearField(field: string) {
      switch (field) {
        case "dhpDue":
          dhpDue.value = "";
          break;
        case "parvovirusDue":
          parvovirusDue.value = "";
          break;
        case "leptospirosisDue":
          leptospirosisDue.value = "";
          break;
        case "bordetellaDue":
          bordetellaDue.value = "";
          break;
        case "rabiesDue":
          rabiesDue.value = "";
          break;
        case "spayedNeutered":
          spayedNeutered.value = "";
          break;

        default:
          break;
      }
      emit("clearMedicalField", field);
    }

    function deleteChip(index: number, type: string) {
      if (type === "medicalNotes" && medicalNotesFiles.value)
        medicalNotesFiles.value.splice(index, 1);
      if (type === "medicalRecords" && medicalRecordsFiles.value) {
        medicalRecordsFiles.value.splice(index, 1);
      }
    }

    function dateInput(type: string) {
      setMedicalInfo();
      switch (type) {
        case "parvovirus":
          parvovirusDateMenu.value = false;
          break;
        case "dhp":
          dhpDateMenu.value = false;
          break;
        case "leptospirosis":
          leptospirosisDateMenu.value = false;
          break;
        case "bordetella":
          bordetellaDateMenu.value = false;
          break;
        case "rabies":
          rabiesDateMenu.value = false;
          break;
        case "spayedneutered":
          spayedNeuteredDateMenu.value = false;
          break;
        default:
          null;
      }
    }

    function setFields(dogMedical: DogMedical) {
      setDateField(leptospirosisDue, dogMedical.leptospirosisDue);
      setDateField(bordetellaDue, dogMedical.bordetellaDue);
      setDateField(dhpDue, dogMedical.dhpDue);
      setDateField(parvovirusDue, dogMedical.parvovirusDue);
      setDateField(rabiesDue, dogMedical.rabiesDue);
      setDateField(spayedNeutered, dogMedical.spayedNeutered);
    }

    function getDate(date: Date | string) {
      date = new Date(date).toISOString().substr(0, 10);
      return moment(String(date)).format("MM/DD/YYYY");
    }

    if (props.update && props.dog && props.dog.medical) {
      setFields(props.dog.medical as DogMedical);
    }

    function showDelete() {
      return !!(
        props.update &&
        ((props.dog || {}).medical ||
          ((existingMedicalNotesFiles.value as DogFile[]) || []).length ||
          ((existingMedicalRecordsFiles.value as DogFile[]) || []).length) &&
        !removeClicked.value
      );
    }

    function deleteMedicalNotesFile(file: DogFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingMedicalNotesFiles.value = (
        (existingMedicalNotesFiles.value as DogFile[]) || []
      ).filter((attachment: DogFile) => attachment.id !== file.id);
    }

    function deleteMedicalRecordsFile(file: DogFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingMedicalRecordsFiles.value = (
        (existingMedicalRecordsFiles.value as DogFile[]) || []
      ).filter((attachment: DogFile) => attachment.id !== file.id);
    }

    async function clearAll() {
      parvovirusDue.value = "";
      dhpDue.value = "";
      leptospirosisDue.value = "";
      bordetellaDue.value = "";
      rabiesDue.value = "";
      spayedNeutered.value = "";
      medicalNotesFiles.value = null;
      medicalRecordsFiles.value = null;
    }

    async function removeMedical() {
      removeClicked.value = true;
      await clearAll();
      for (const file of (existingMedicalNotesFiles.value as DogFile[]) || []) {
        await deleteMedicalNotesFile(file);
      }
      for (const file of (existingMedicalRecordsFiles.value as DogFile[]) ||
        []) {
        await deleteMedicalRecordsFile(file);
      }
      showMedicalRecordsInput.value = false;
      showMedicalNotesInput.value = false;
      emit("setMedical", {
        valid: valid.value,
        type: "delete",
      });
    }

    function showNotesFileInput() {
      return !!(
        !props.update ||
        (props.update && showMedicalNotesInput.value) ||
        (props.update &&
          !((existingMedicalNotesFiles.value as DogFile[]) || []).length)
      );
    }

    function showRecordsFileInput() {
      return !!(
        !props.update ||
        (props.update && showMedicalRecordsInput.value) ||
        (props.update &&
          !((existingMedicalRecordsFiles.value as DogFile[]) || []).length)
      );
    }

    return {
      dogMedicalForm,
      valid,
      parvovirusDateMenu,
      parvovirusDue,
      dhpDateMenu,
      dhpDue,
      leptospirosisDateMenu,
      leptospirosisDue,
      bordetellaDateMenu,
      bordetellaDue,
      rabiesDateMenu,
      rabiesDue,
      spayedNeuteredDateMenu,
      spayedNeutered,
      changeDate,
      medicalNotesFiles,
      medicalRecordsFiles,
      deleteChip,
      fileArrayRules,
      setMedicalInfo,
      dateInput,
      clearField,
      existingMedicalNotesFiles,
      existingMedicalRecordsFiles,
      getDate,
      showMedicalRecordsInput,
      showMedicalNotesInput,
      showDelete,
      deleteMedicalNotesFile,
      deleteMedicalRecordsFile,
      removeMedical,
      showNotesFileInput,
      showRecordsFileInput,
    };
  },
});
