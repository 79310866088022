
































import { ApplicantFile, DogFile } from "@/store";
import { defineComponent, ref, watch } from "@vue/composition-api";
import {
  useStore,
  useApplicantService,
  useDogService,
} from "@/composables/providers";
import { ApplicantActions } from "@/store/applicant";
import { DogActions } from "@/store/dog";

interface Props {
  attachment: ApplicantFile | DogFile;
  type: string;
}

export default defineComponent({
  name: "FileDialog",
  props: {
    attachment: Object,
    type: String,
  },
  setup(props: Props) {
    const store = useStore();
    const service = useApplicantService();
    const dogService = useDogService();
    const dialog = ref<boolean>(false);
    const url = ref<string>("");

    async function getApplicantFile() {
      url.value = await store.dispatch(ApplicantActions.GET_FILE, {
        id: props.attachment.id,
        service,
      });
    }

    async function getDogFile() {
      url.value = await store.dispatch(DogActions.GET_FILE, {
        id: props.attachment.id,
        service: dogService,
      });
    }

    watch(dialog, () => {
      if (dialog.value && props.type === "dog") {
        getDogFile();
      }

      if (dialog.value && props.type === "applicant") {
        getApplicantFile();
      }
    });

    return {
      dialog,
      url,
    };
  },
});
