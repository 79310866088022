





































































































































































































































































































import { defineComponent, ref, watch, onMounted } from "@vue/composition-api";
import {
  DogCreateBody,
  Dog,
  DogUpdateBody,
  RuleFunction,
  Applicant,
  DogTraining,
  DogTrainerTableDisplayBody,
  DogNote,
  User,
  DogVeterinarian,
  DogMedicalCreateBody,
  DogMedical,
} from "@/store";
import { DOG_TRAINING_STATUS } from "@/store/constants";
import {
  useNameValidation,
  useDateValidation,
  usePhotoValidation,
} from "@/composables/validation";
import DogPlacementForm from "./DogPlacement.vue";
import DogNoteForm from "./DogNote.vue";
import DogTrainingsTable from "@/components/tables/DogTraining.vue";
import DogVeterinarianForm from "./DogVeterinarian.vue";
import DogAcquisitionForm from "./DogAcquisition.vue";
import DogMedicalForm from "./DogMedical.vue";

interface Props {
  loading: boolean;
  update: boolean;
  dog?: Dog;
  user?: User;
  applicants?: Applicant[];
  dogTrainers?: User[];
  users?: User[];
  isAdmin?: boolean;
  isStaffOrHigher?: boolean;
}

export default defineComponent({
  name: "DogForm",
  props: {
    loading: Boolean,
    update: Boolean,
    dog: Object,
    applicants: Array,
    user: Object,
    users: Array,
    dogTrainers: Array,
    isAdmin: Boolean,
    isStaffOrHigher: Boolean,
  },
  components: {
    DogPlacementForm,
    DogVeterinarianForm,
    DogNoteForm,
    DogTrainingsTable,
    DogAcquisitionForm,
    DogMedicalForm,
  },
  setup(props: Props, { emit }) {
    const newNote = ref<DogNote | null>(null);
    const name = ref<string>("");
    const breed = ref<string>("");
    const color = ref<string>("");
    const microchipBrand = ref<string>("");
    const microchipId = ref<string>("");
    const birthDate = ref<Date | string>("");
    const photo = ref<File | null>(null);
    const valid = ref<boolean>(false);
    const dogForm = ref<{ validate: Function } | null>(null);
    const birthDateMenu = ref<boolean>(false);
    const picker = ref<{ activePicker: string }>();
    const { photoRules } = usePhotoValidation();
    const { firstNameRules } = useNameValidation();
    const { today, dogBirthDateRules } = useDateValidation();
    const validate = ref<boolean>(false);
    const placementValid = ref<boolean>(true);
    const placementError = ref<boolean>(false);
    const placementUpdated = ref<boolean>(false);
    const deletedFiles = ref<number[]>([]);
    const dogNote = ref<DogNote[]>([]);
    const noteError = ref<boolean>(false);
    const newNoteAdded = ref<boolean>(false);
    const notesValid = ref<boolean>(true);
    const notes = ref<DogNote[] | undefined>(
      (((props.dog || {}).notes as DogNote[]) || []).sort(
        (a: DogNote, b: DogNote) => {
          const dateA = new Date(a.createDate).getTime();
          const dateB = new Date(b.createDate).getTime();
          if (dateA > dateB) {
            return 1;
          }
          if (dateB > dateA) {
            return -1;
          }
          return 0;
        }
      )
    );

    const notesUpdated = ref<boolean>(false);
    const photoSource = ref<string | undefined>(
      props.dog && props.dog.displayPhoto
        ? props.dog.displayPhoto
        : `${require("../../assets/placeholder.png")}`
    );
    const updatedPhoto = ref<boolean>(false);
    const recipient = ref<number | null>(null);
    const placedWithRecipient = ref<boolean>(false);
    const adoptionDate = ref<Date | string>("");
    const adoptionFile = ref<File | null>(null);
    const placementDate = ref<Date | string>("");
    const retired = ref<boolean | null>(null);
    const deceased = ref<boolean | null>(null);
    const trainingCertExpires = ref<Date | string>("");
    const trainingCertMenu = ref<boolean>(false);
    const trainingStatus = ref<number | null>(null);
    const trainingsUpdated = ref<boolean>(false);
    const trainings = ref<DogTraining[] | null>();
    const medical = ref<DogMedicalCreateBody | null>(null);
    const veterinarian = ref<DogVeterinarian | null>(null);
    const veterinarianValid = ref<boolean>(true);
    const veterinarianError = ref<boolean>(false);
    const veterinarianUpdated = ref<boolean>(false);
    const acquiredDate = ref<Date | string>("");
    const acquisitionType = ref<number | null>();
    const acquisitionFiles = ref<File[] | null>(null);
    const acquisitionError = ref<boolean>(false);
    const acquisitionValid = ref<boolean>(true);
    const existingVeterinarian = ref<number | null>(
      (((props.dog || {}).veterinarian || {}).id as number) || null
    );
    const acquisitionUpdated = ref<boolean>(false);
    const medicalNotesFiles = ref<File[] | null>(null);
    const medicalRecordsFiles = ref<File[] | null>(null);
    const medicalValid = ref<boolean>(true);
    const medicalError = ref<boolean>(false);
    const medicalUpdated = ref<boolean>(false);
    const medicalFilesUpdated = ref<boolean>(false);
    const updatedVet = ref<boolean>(false);

    watch(
      props,
      () => {
        trainings.value = (props.dog || {}).dogTrainings as DogTraining[];
        if ((props.dog || {}).applicant) {
          placedWithRecipient.value = true;
        }
      },
      {
        immediate: true,
      }
    );

    watch(recipient, () => {
      if (recipient.value === 0 || recipient.value === null) {
        placedWithRecipient.value = false;
      }
    });

    function updateFormFromProps() {
      if (props.update && props.dog) {
        name.value = props.dog.name;
        birthDate.value = new Date(props.dog.birthDate)
          .toISOString()
          .substr(0, 10);
        breed.value = props.dog.breed;
        color.value = props.dog.color;
        if (props.dog.microchipBrand) {
          microchipBrand.value = props.dog.microchipBrand;
        }
        if (props.dog.microchipId) {
          microchipId.value = props.dog.microchipId;
        }
        if (props.dog.applicant) {
          recipient.value = props.dog.applicant.id;
        }
        if (props.dog.adoptionDate) {
          adoptionDate.value = props.dog.adoptionDate;
        }
        if (props.dog.retired) {
          retired.value = props.dog.retired;
        }
        if (props.dog.deceased) {
          deceased.value = props.dog.deceased;
        }
        if (props.dog.placementDate) {
          placementDate.value = props.dog.placementDate;
        }
        if (props.dog.dogTrainings) {
          trainings.value = props.dog.dogTrainings;
        }
        if (props.dog.veterinarian) {
          veterinarian.value = props.dog.veterinarian;
        }
        if (props.dog.notes) {
          dogNote.value = notes.value as DogNote[];
        }
        if (props.dog.trainingStatus) {
          trainingStatus.value = props.dog.trainingStatus;
        }
        if (props.dog.trainingCertExpires) {
          trainingCertExpires.value = new Date(props.dog.trainingCertExpires)
            .toISOString()
            .substr(0, 10);
        }
        if (props.dog.acquiredDate) {
          acquiredDate.value = props.dog.acquiredDate;
        }

        if (props.dog.acquisitionType) {
          acquisitionType.value = props.dog.acquisitionType;
        }

        if (props.dog.medical) {
          medical.value = props.dog.medical;
        }
      }
    }

    onMounted(() => {
      updateFormFromProps();
    });

    watch(
      () => props.loading,
      () => {
        updateFormFromProps();
      }
    );

    watch(birthDateMenu, (newVal) => {
      if (newVal && picker.value) {
        picker.value.activePicker = "YEAR";
      }
    });

    const panel = ref<number[]>([0]);
    const expanded = ref<boolean>(true);

    watch(panel, (newVal) => {
      if (newVal && newVal.length > 0) {
        expanded.value = true;
      } else {
        expanded.value = false;
      }
    });

    function getClearNote(event: {
      valid: boolean;
      note?: DogNote;
      index?: number;
      type: string;
    }) {
      return !!(
        (event.note &&
          event.note?.text?.length <= 3 &&
          (event.index || 0) &&
          dogNote.value[0]) ||
        event.type === "new"
      );
    }

    function checkValidNote(event: {
      valid: boolean;
      note?: DogNote;
      index?: number;
      type: string;
    }) {
      return !!(
        !noteError.value &&
        event.note &&
        (event.note.text || "").length > 3
      );
    }

    function setDogNote(event: {
      valid: boolean;
      note?: DogNote;
      index?: number;
      type: string;
    }) {
      noteError.value = !event.valid;
      if (checkValidNote(event) && event.type !== "new") {
        dogNote.value[event.index || 0] = event.note as DogNote;
        notesUpdated.value = true;
      } else if (checkValidNote(event) && event.type === "new") {
        newNote.value = event.note as DogNote;
        newNoteAdded.value = true;
      } else if (getClearNote(event)) {
        if (event.type !== "new") dogNote.value.splice(event.index || 0, 1);
        else {
          newNote.value = null;
          newNoteAdded.value = false;
        }
      }
      notesValid.value = event.valid;
    }

    function deleteNote(event: number) {
      notesValid.value = true;
      noteError.value = false;
      dogNote.value = dogNote.value.filter((note) => note.id !== event);
      notesUpdated.value = true;
    }

    function openPanels() {
      panel.value = [0];

      if (acquisitionError.value) {
        panel.value.push(1);
      }

      if (placementError.value) {
        panel.value.push(3);
      }

      if (veterinarianError.value) {
        panel.value.push(4);
      }

      if (medicalError.value && !props.dog?.placementDate) {
        panel.value.push(4);
      } else if (medicalError.value) {
        panel.value.push(5);
      }

      if (noteError.value && !props.dog?.placementDate) {
        panel.value.push(5);
      } else if (noteError.value) {
        panel.value.push(6);
      }
    }

    function closeOrOpenPanels() {
      if (expanded.value) {
        panel.value = [];
      } else {
        if (!props.update) {
          panel.value = [0, 1, 3, 4];
        } else if (!props.dog?.placementDate) {
          panel.value = [0, 1, 2, 3, 4, 5];
        } else {
          panel.value = [0, 1, 2, 3, 4, 5, 6];
        }
      }
    }

    function changePhoto(event: File) {
      photoSource.value = URL.createObjectURL(event);
      updatedPhoto.value = true;
    }

    function setDogAcquisition(event: {
      valid: boolean;
      acquisitionData?: {
        acquiredDate?: Date | string;
        acquisitionType?: number;
        acquisitionFiles?: File[] | null;
      };
      type: string;
    }) {
      acquisitionError.value = !event.valid;
      if (event.acquisitionData && event.valid) {
        acquiredDate.value = event.acquisitionData.acquiredDate as string;
        acquisitionType.value = event.acquisitionData.acquisitionType as number;
        acquisitionFiles.value = event.acquisitionData
          .acquisitionFiles as File[];
      }
      if (event.type === "delete") {
        if (props.dog && props.dog.acquisitionType) {
          props.dog.acquisitionType = null;
        }
        acquisitionType.value = 0;
        acquiredDate.value = "";
        acquisitionFiles.value = null;
      }

      if (props.update && (event.acquisitionData || event.type === "delete"))
        acquisitionUpdated.value = true;

      acquisitionValid.value = event.valid;
    }

    function isMedicalEmpty() {
      return !!(
        medical.value &&
        (medical.value.bordetellaDue === "" ||
          medical.value.bordetellaDue === null) &&
        (medical.value.dhpDue === "" || medical.value.dhpDue === null) &&
        (medical.value.leptospirosisDue === "" ||
          medical.value.leptospirosisDue === null) &&
        (medical.value.parvovirusDue === "" ||
          medical.value.parvovirusDue === null) &&
        (medical.value.rabiesDue === "" || medical.value.rabiesDue === null) &&
        (medical.value.spayedNeutered === "" ||
          medical.value.spayedNeutered === null)
      );
    }

    function wasMedicalUpdated() {
      const compareMedical: Partial<DogMedicalCreateBody> = {};

      if (props.dog) {
        if (!props.dog.medical && isMedicalEmpty()) {
          return false;
        }

        if (props.dog.medical || medical.value) {
          const dog =
            props.dog.medical || ({} as Partial<DogMedicalCreateBody>);
          Object.entries(medical.value as DogMedicalCreateBody).forEach(
            ([key, value]) => {
              if (key !== "id") {
                if (!value || value === undefined) {
                  compareMedical[key as keyof DogMedicalCreateBody] = null;
                } else {
                  (compareMedical[
                    key as keyof DogMedicalCreateBody
                  ] as string) = new Date(value).toISOString();
                }
              }
            }
          );
          return !!(
            ((compareMedical.bordetellaDue ||
              compareMedical.bordetellaDue === null) &&
              compareMedical.bordetellaDue !== dog?.bordetellaDue) ||
            ((compareMedical.parvovirusDue ||
              compareMedical.parvovirusDue === null) &&
              compareMedical.parvovirusDue !== dog?.parvovirusDue) ||
            ((compareMedical.dhpDue || compareMedical.dhpDue === null) &&
              compareMedical.dhpDue !== dog?.dhpDue) ||
            ((compareMedical.spayedNeutered ||
              compareMedical.spayedNeutered === null) &&
              compareMedical.spayedNeutered !== dog?.spayedNeutered) ||
            ((compareMedical.leptospirosisDue ||
              compareMedical.leptospirosisDue === null) &&
              compareMedical.leptospirosisDue !== dog?.leptospirosisDue) ||
            ((compareMedical.rabiesDue || compareMedical.rabiesDue === null) &&
              compareMedical.rabiesDue !== dog?.rabiesDue)
          );
        }
      }

      return true;
    }

    function setDogMedical(event: {
      valid: boolean;
      medicalData?: DogMedicalCreateBody;
      files?: {
        medicalNotesFiles: File[];
        medicalRecordsFiles: File[];
      };
      type: string;
    }) {
      medicalError.value = !event.valid;

      if (!medicalError.value && event.medicalData) {
        medical.value = {} as Partial<DogMedicalCreateBody>;
        if (
          event.medicalData.bordetellaDue ||
          (props.update && event.medicalData.bordetellaDue === "")
        ) {
          medical.value.bordetellaDue = event.medicalData.bordetellaDue;
        }
        if (
          event.medicalData.parvovirusDue ||
          (props.update && event.medicalData.parvovirusDue === "")
        ) {
          medical.value.parvovirusDue = event.medicalData.parvovirusDue;
        }
        if (
          event.medicalData.leptospirosisDue ||
          (props.update && event.medicalData.leptospirosisDue === "")
        ) {
          medical.value.leptospirosisDue = event.medicalData.leptospirosisDue;
        }
        if (
          event.medicalData.dhpDue ||
          (props.update && event.medicalData.dhpDue === "")
        ) {
          medical.value.dhpDue = event.medicalData.dhpDue;
        }
        if (
          event.medicalData.rabiesDue ||
          (props.update && event.medicalData.rabiesDue === "")
        ) {
          medical.value.rabiesDue = event.medicalData.rabiesDue;
        }
        if (
          event.medicalData.spayedNeutered ||
          (props.update && event.medicalData.spayedNeutered === "")
        ) {
          medical.value.spayedNeutered = event.medicalData.spayedNeutered;
        }
      }

      if (!medicalError.value && event.files?.medicalNotesFiles) {
        medicalNotesFiles.value = event.files.medicalNotesFiles;
      }

      if (!medicalError.value && event.files?.medicalRecordsFiles) {
        medicalRecordsFiles.value = event.files.medicalRecordsFiles;
      }

      if (event.type === "delete") {
        if (props.dog) {
          delete props.dog.medical;
        }
        medicalRecordsFiles.value = null;
        medicalNotesFiles.value = null;
        medical.value = null;
      }

      if (props.update && event.medicalData && event.type !== "delete") {
        medicalUpdated.value = wasMedicalUpdated() as boolean;
      } else if (props.update && event.type === "delete") {
        medicalUpdated.value = true;
      }

      if (
        props.update &&
        ((event.files?.medicalNotesFiles || []).length ||
          (event.files?.medicalRecordsFiles || []).length)
      ) {
        medicalFilesUpdated.value = true;
      }

      medicalValid.value = event.valid;
    }

    function clearMedicalField(event: string) {
      switch (event) {
        case "dhpDue":
          if (props.update && props.dog) {
            (medical.value || {}).dhpDue = "";
          } else delete (medical.value || {}).dhpDue;
          break;
        case "parvovirusDue":
          if (props.update && props.dog) {
            (medical.value || {}).parvovirusDue = "";
          } else delete (medical.value || {}).parvovirusDue;
          break;
        case "leptospirosisDue":
          if (props.update && props.dog) {
            (medical.value || {}).leptospirosisDue = "";
          } else delete (medical.value || {}).leptospirosisDue;
          break;
        case "bordetellaDue":
          if (props.update && props.dog) {
            (medical.value || {}).bordetellaDue = "";
          } else delete (medical.value || {}).bordetellaDue;
          break;
        case "rabiesDue":
          if (props.update && props.dog) {
            (medical.value || {}).rabiesDue = "";
          } else delete (medical.value || {}).rabiesDue;
          break;
        case "spayedNeutered":
          if (props.update && props.dog) {
            (medical.value || {}).spayedNeutered = "";
          } else delete (medical.value || {}).spayedNeutered;
          break;

        default:
          break;
      }

      if (props.update) {
        medicalUpdated.value = wasMedicalUpdated() as boolean;
        if (props.dog?.medical && isMedicalEmpty()) {
          setDogMedical({ valid: true, type: "delete" });
        }
      }
    }

    function resetVet(event: {
      valid: boolean;
      type?: string;
      placementData?: {
        recipient?: number;
        placementDate?: Date | string;
        adoptionDate?: Date | string;
        adoptionFile?: File | null;
      };
    }) {
      return !!(
        !updatedVet.value &&
        props.dog &&
        props.dog.applicant &&
        props.dog.applicant.id !== event.placementData?.recipient
      );
    }

    function setDogPlacement(event: {
      valid: boolean;
      type?: string;
      placementData?: {
        recipient?: number;
        placementDate?: Date | string;
        adoptionDate?: Date | string;
        adoptionFile?: File | null;
      };
    }) {
      placementError.value = !event.valid;
      if (event.valid) {
        switch (event.type) {
          case "placement":
            recipient.value = event.placementData
              ? (event.placementData.recipient as number)
              : null;
            placementDate.value = event.placementData
              ? (event.placementData.placementDate as string)
              : "";
            adoptionDate.value = "";
            placedWithRecipient.value = true;
            retired.value = false;
            deceased.value = false;
            if (resetVet(event)) {
              updatedVet.value = true;
              veterinarian.value = null;
            }
            break;

          case "adoption":
            if (event.placementData) {
              adoptionDate.value = event.placementData.adoptionDate as string;
              adoptionFile.value = event.placementData.adoptionFile
                ? event.placementData.adoptionFile
                : null;
            }
            placementDate.value = "";
            placedWithRecipient.value = false;
            veterinarian.value = null;
            recipient.value = 0;
            retired.value = false;
            deceased.value = false;
            break;

          case "retired":
            retired.value = true;
            placementDate.value = "";
            adoptionDate.value = "";
            adoptionFile.value = null;
            recipient.value = 0;
            placedWithRecipient.value = false;
            veterinarian.value = null;
            deceased.value = false;
            break;

          case "deceased":
            deceased.value = true;
            placementDate.value = "";
            adoptionDate.value = "";
            adoptionFile.value = null;
            recipient.value = 0;
            placedWithRecipient.value = false;
            veterinarian.value = null;
            retired.value = false;
            break;

          case "delete":
            if (props.dog) {
              props.dog.placementDate = "";
              props.dog.adoptionDate = "";
              props.dog.retired = null;
              props.dog.deceased = null;
            }
            placementDate.value = "";
            adoptionDate.value = "";
            adoptionFile.value = null;
            recipient.value = 0;
            placedWithRecipient.value = false;
            veterinarian.value = null;
            retired.value = false;
            deceased.value = false;
            break;

          default:
            break;
        }
      }

      placementValid.value = event.valid;
      if (
        props.update &&
        (event.placementData ||
          event.type === "retired" ||
          event.type === "deceased" ||
          event.type === "delete")
      ) {
        placementUpdated.value = true;
      }
    }

    function getVeterinarianValidEvent(event: {
      valid: boolean;
      veterinarian?: DogVeterinarian;
      deleted?: boolean;
    }) {
      return !!(
        event.veterinarian &&
        event.veterinarian.firstName &&
        event.veterinarian.lastName &&
        event.veterinarian.phone.length > 2 &&
        event.veterinarian.practiceName
      );
    }

    function setDogVeterinarian(event: {
      valid: boolean;
      veterinarian?: DogVeterinarian;
      deleted?: boolean;
    }) {
      veterinarianError.value = !event.valid;
      if (
        !veterinarianError.value &&
        event.veterinarian &&
        getVeterinarianValidEvent(event)
      ) {
        veterinarian.value = event.veterinarian;
        veterinarian.value.phone = "+1" + event.veterinarian.phone;

        if (existingVeterinarian.value && props.update) {
          veterinarian.value.id = existingVeterinarian.value;
        }
      }
      if (existingVeterinarian.value && event.deleted) {
        veterinarian.value = (props.dog || {}).veterinarian as DogVeterinarian;
        veterinarian.value.delete = event.deleted;
      }
      if (props.update && (getVeterinarianValidEvent(event) || event.deleted))
        veterinarianUpdated.value = true;
      else veterinarianUpdated.value = false;
      veterinarianValid.value = event.valid;
    }

    function getUpdates(): DogUpdateBody {
      const body: DogUpdateBody = {};
      if (props.dog) {
        if (name.value && name.value !== props.dog.name) {
          body.name = name.value;
        }
        if (breed.value && breed.value !== props.dog.breed) {
          body.breed = breed.value;
        }
        if (color.value && color.value !== props.dog.color) {
          body.color = color.value;
        }
        if (
          birthDate.value &&
          new Date(birthDate.value).toISOString().substr(0, 10) !==
            new Date(props.dog.birthDate).toISOString().substr(0, 10)
        ) {
          body.birthDate = birthDate.value;
        }
        if (
          microchipBrand.value &&
          microchipBrand.value !== props.dog.microchipBrand
        ) {
          body.microchipBrand = microchipBrand.value;
        }
        if (microchipId.value && microchipId.value !== props.dog.microchipId) {
          body.microchipId = microchipId.value;
        }
        if (updatedPhoto && photo.value) {
          body.photo = photo.value;
        }
        if (placementUpdated.value) {
          body.adoptionDate = adoptionDate.value as string;
          if (adoptionFile.value) {
            body.adoptionFile = adoptionFile.value;
          }
          body.placementDate = placementDate.value as string;
          body.recipient = recipient.value as number;
          body.retired = retired.value;
          body.deceased = deceased.value;
        }

        if (acquisitionUpdated.value) {
          body.acquisitionType = acquisitionType.value;
          body.acquiredDate = acquiredDate.value;
          body.acquisitionFiles = acquisitionFiles.value;
        }
        if (trainingsUpdated.value) {
          body.dogTrainings = trainings.value;
        }
        if (trainingStatus.value !== props.dog.trainingStatus) {
          body.trainingStatus = trainingStatus.value ?? 0;
        }
        if (
          new Date(trainingCertExpires.value || 0)
            .toISOString()
            .substr(0, 10) !==
          new Date(props.dog.trainingCertExpires || 0)
            .toISOString()
            .substr(0, 10)
        ) {
          body.trainingCertExpires = trainingCertExpires.value;
        }
        if (veterinarianUpdated.value) {
          body.veterinarian = veterinarian.value;
        }
        if (notesUpdated.value || newNoteAdded.value) {
          if (newNote.value && newNoteAdded.value)
            dogNote.value.push(newNote.value);
          body.notes = dogNote.value;
        }

        if (deletedFiles.value.length) {
          body.deletedFiles = deletedFiles.value;
        }
      }

      if (medicalUpdated.value) {
        body.medical = medical.value as DogMedical | null;
      }

      if (
        medicalFilesUpdated.value &&
        ((medicalNotesFiles.value || []).length ||
          (medicalRecordsFiles.value || []).length)
      ) {
        body.medicalNotesFiles = medicalNotesFiles.value as File[];
        body.medicalRecordsFiles = medicalRecordsFiles.value as File[];
      }
      return body;
    }

    function submit() {
      if (
        valid.value &&
        placementValid.value &&
        veterinarianValid.value &&
        notesValid.value &&
        acquisitionValid.value &&
        medicalValid.value
      ) {
        validate.value = false;
        if (props.update && props.dog) {
          const body = getUpdates();
          if (Object.keys(body).length !== 0) {
            emit("update", body);
          } else {
            return;
          }
        } else {
          const body: DogCreateBody = {
            name: name.value,
            breed: breed.value,
            color: color.value,
            birthDate: birthDate.value,
            microchipBrand: microchipBrand.value,
            microchipId: microchipId.value,
          };

          if (photo.value) {
            body.photo = photo.value;
          }

          if (recipient.value) {
            body.recipient = recipient.value;
          }

          if (adoptionDate.value) {
            body.adoptionDate = adoptionDate.value;
          }

          if (placementDate.value) {
            body.placementDate = placementDate.value;
          }

          if (retired.value) {
            body.retired = retired.value;
          }

          if (deceased.value) {
            body.deceased = deceased.value;
          }

          if (veterinarian.value) {
            body.veterinarian = veterinarian.value;
          }

          if (newNote.value) {
            body.notes = [newNote.value];
          }

          if (acquisitionFiles.value) {
            body.acquisitionFiles = acquisitionFiles.value;
          }

          if (acquiredDate.value) {
            body.acquiredDate = acquiredDate.value;
          }

          if (acquisitionType.value) {
            body.acquisitionType = acquisitionType.value;
          }

          if (medical.value) {
            body.medical = medical.value;
          }

          if (medicalNotesFiles.value) {
            body.medicalNotesFiles = medicalNotesFiles.value;
          }

          if (medicalRecordsFiles.value) {
            body.medicalRecordsFiles = medicalRecordsFiles.value;
          }
          emit("create", body);
        }
      } else {
        if (dogForm.value) {
          openPanels();
          validate.value = true;
          dogForm.value.validate();
        }
      }
    }

    const colorRules = ref<RuleFunction<string>[]>([
      (value: string) => (!!value && value.length >= 1) || "Color is required",
      (value: string) =>
        value.length <= 100 || "Color must be less than 100 characters long",
    ]);

    const breedRules = ref<RuleFunction<string>[]>([
      (value: string) => (!!value && value.length >= 1) || "Breed is required",
      (value: string) =>
        value.length <= 100 || "Breed must be less than 100 characters long",
    ]);

    const microchipBrandRules = ref<RuleFunction<string>[]>([
      (value: string) =>
        value?.length <= 25 ||
        "Microchip brand must be less than 25 characters long",
    ]);

    const microchipIdRules = ref<RuleFunction<string>[]>([
      (value: string) =>
        value?.length <= 20 ||
        "Microchip ID number must be less than 20 characters long",
      (value: string) =>
        /^[a-z0-9]*$/gi.test(value) ||
        "Microchip ID number must contain letters and/or number",
    ]);

    function changeDate(event: Date, type: string) {
      switch (type) {
        case "birthDate":
          birthDate.value = new Date(event).toISOString().substr(0, 10);
          break;
        case "trainingCertExpires":
          trainingCertExpires.value = new Date(event)
            .toISOString()
            .substr(0, 10);
          break;
        default:
          null;
      }
    }

    function deleteTraining($event: DogTraining | DogTrainerTableDisplayBody) {
      const index = (trainings.value || []).indexOf($event);
      if (index > -1) {
        trainings.value = (trainings.value || []).filter(
          (training) => training !== (trainings.value || [])[index]
        );
      }
      trainingsUpdated.value = true;
    }

    function createTraining($event: DogTraining | DogTrainerTableDisplayBody) {
      (trainings.value || []).push($event);
      trainings.value = (trainings.value || []).filter(
        (training) => training.id !== $event.id
      );
      trainingsUpdated.value = true;
    }

    function deleteFile($event: number[]) {
      deletedFiles.value = $event;
    }

    return {
      DOG_TRAINING_STATUS,
      trainingStatus,
      trainingCertMenu,
      trainingCertExpires,
      name,
      birthDate,
      breed,
      color,
      microchipBrand,
      microchipId,
      photo,
      today,
      submit,
      valid,
      dogForm,
      firstNameRules,
      dogBirthDateRules,
      breedRules,
      colorRules,
      microchipBrandRules,
      microchipIdRules,
      photoRules,
      birthDateMenu,
      changeDate,
      openPanels,
      panel,
      expanded,
      closeOrOpenPanels,
      picker,
      changePhoto,
      photoSource,
      updatedPhoto,
      getUpdates,
      updateFormFromProps,
      validate,
      placementError,
      placementValid,
      setDogPlacement,
      deleteTraining,
      createTraining,
      trainings,
      veterinarian,
      veterinarianValid,
      veterinarianUpdated,
      veterinarianError,
      existingVeterinarian,
      setDogVeterinarian,
      setDogNote,
      dogNote,
      notes,
      deleteNote,
      placedWithRecipient,
      deleteFile,
      setDogAcquisition,
      setDogMedical,
      clearMedicalField,
      retired,
      deceased,
    };
  },
});
