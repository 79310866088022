















































































import { defineComponent, ref, watch, watchEffect } from "@vue/composition-api";
import { RuleFunction, DogVeterinarian } from "@/store";
import {
  useNameValidation,
  usePhoneValidation,
  useEmailValidation,
} from "@/composables/validation";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";

interface Props {
  loading: boolean;
  update?: boolean;
  veterinarian: DogVeterinarian;
  validate?: boolean;
}

export default defineComponent({
  name: "DogVeterinarianForm",
  props: {
    loading: Boolean,
    update: Boolean,
    veterinarian: Object,
    validate: Boolean,
  },
  components: {
    ConfirmationDialog,
  },
  setup(props: Props, { emit }) {
    const valid = ref<boolean>(false);
    const readOnly = ref<boolean>(false);
    const veterinarianForm = ref<{ validate: Function } | null>(null);
    const { firstNameRules, lastNameRules, nameRules } = useNameValidation();
    const { phoneMask, phoneRules } = usePhoneValidation();
    const emailRules = ref<RuleFunction<string>[]>([]);
    const firstName = ref<string>("");
    const lastName = ref<string>("");
    const phone = ref<string>("");
    const email = ref<string>("");
    const practiceName = ref<string>("");

    watchEffect(() => {
      if (props.validate && veterinarianForm.value) {
        veterinarianForm.value.validate();
      }
    });

    const setRequired = () => {
      const allNameRules = useNameValidation();
      firstNameRules.value = allNameRules.firstNameRules.value;
      lastNameRules.value = allNameRules.lastNameRules.value;
      phoneRules.value = usePhoneValidation().phoneRules.value;
      emailRules.value = email.value
        ? useEmailValidation(false).emailRules.value
        : [];
      nameRules.value = allNameRules.nameRules.value;
    };

    const setNotRequired = () => {
      firstNameRules.value = [];
      lastNameRules.value = [];
      phoneRules.value = [];
      nameRules.value = [];
      emailRules.value = [];
    };

    const getDeleted = () => {
      if (props.update) {
        return (
          !(firstName.value || "").trim().length &&
          !(lastName.value || "").trim().length &&
          !(phone.value || "").trim().length &&
          !(practiceName.value || "").trim().length &&
          !(email.value || "").trim().length
        );
      } else return;
    };

    const setFields = async (veterinarian: DogVeterinarian) => {
      firstName.value = veterinarian.firstName;
      lastName.value = veterinarian.lastName;
      phone.value = veterinarian.phone.slice(2, 13);
      practiceName.value = veterinarian.practiceName;
      if (veterinarian.email) {
        email.value = veterinarian.email;
      }
      await setRequired();
    };

    const clearAll = async () => {
      await setNotRequired();
      firstName.value = "";
      lastName.value = "";
      phone.value = "";
      email.value = "";
      practiceName.value = "";
    };

    const deleteVeterinarian = async () => {
      await clearAll();
      if (props.update && props.veterinarian) {
        emit("dogVeterinarian", {
          valid: valid.value,
          deleted: getDeleted(),
        });
      }
    };

    const setVeterinarian = async () => {
      if (
        (practiceName.value || "").trim().length ||
        (firstName.value || "").trim().length ||
        (lastName.value || "").trim().length ||
        (phone.value || "").trim().length ||
        (email.value || "").trim().length
      ) {
        await setRequired();
      } else {
        await setNotRequired();
      }
      emit("dogVeterinarian", valid.value);
      watchEffect(() => {
        if (valid.value && veterinarianForm.value) {
          setTimeout(() => {
            emit("dogVeterinarian", {
              veterinarian: {
                firstName: firstName.value,
                lastName: lastName.value,
                phone: phone.value,
                email: email.value,
                practiceName: practiceName.value,
              },
              valid: valid.value,
              deleted: getDeleted(),
            });
          }, 300);
        }
      });
    };

    const showDelete = () => {
      return !!(props.update && (props.veterinarian || {}).id);
    };

    if (props.update) {
      if (props.veterinarian) {
        setFields(props.veterinarian as DogVeterinarian);
      } else {
        setNotRequired();
      }
    }

    watch(
      props,
      () => {
        if (!props.veterinarian) {
          clearAll();
        }
      },
      { immediate: true }
    );

    return {
      valid,
      veterinarianForm,
      firstName,
      lastName,
      phone,
      email,
      practiceName,
      firstNameRules,
      lastNameRules,
      phoneMask,
      phoneRules,
      emailRules,
      nameRules,
      setVeterinarian,
      deleteVeterinarian,
      showDelete,
      readOnly,
    };
  },
});
