






























import { defineComponent, computed, onBeforeMount } from "@vue/composition-api";
import { DogCreateBody, Applicant, User } from "@/store";
import DogForm from "@/components/forms/Dog.vue";
import { DogActions, DogGetters } from "../../store/dog";
import {
  useStore,
  useDogService,
  useRouter,
  useApplicantService,
} from "@/composables/providers";
import { ApplicantActions, ApplicantGetters } from "@/store/applicant";
import { SessionGetters } from "@/store/session";

export default defineComponent({
  name: "DogsCreate",
  components: {
    DogForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const dogService = useDogService();
    const applicantService = useApplicantService();
    const dogsLoading = computed<boolean>(
      () => store.getters[DogGetters.LOADING]
    );
    const currentUser = computed<User>(
      () => store.getters[SessionGetters.USER]
    );
    const applicantsLoading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => applicantsLoading.value || dogsLoading.value
    );
    const applicants = computed<Applicant[]>(
      () => store.getters[ApplicantGetters.APPLICANTS]
    );
    async function submit(body: DogCreateBody) {
      const dog = await store.dispatch(DogActions.CREATE, {
        body,
        service: dogService,
      });
      if (dog && dog.id) {
        await store.dispatch(DogActions.SELECT, {
          id: dog.id,
          service: dogService,
        });
        await router.push({ name: "DogDetail", params: { id: dog.id } });
      }
    }

    onBeforeMount(async () => {
      await store.dispatch(ApplicantActions.LIST, {
        service: applicantService,
      });
    });

    return {
      submit,
      loading,
      applicants,
      currentUser,
    };
  },
});
